import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Popover,
  Tooltip,
  Avatar,
  ListItemSecondaryAction,
  FormControlLabel,
  Checkbox,
  Grid,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { HeaderStyles, WizardStyles } from '../../assets/css';
import { useUI } from '../../app/context/ui';
import { ActivityService, CustomerService, AlarmService } from '../../services';
import { addNotification } from '../../app/store/mm/notificationSlice';
import { addAlarm } from '../../app/store/mm/alarmSlice';
import { useHistory } from 'react-router-dom';
import { addCustomer } from '../../app/store/mm/customerSlice';
import { useDispatch } from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { isEmpty as _isEmpty } from 'lodash';
import { NotificationsStyle } from './styles/NotificationsStyle';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import Notification from './components/Notification';
import Alarms from './components/Alarms/Alarms';
import AppHelper from '../../helpers/AppHelper';
import DetailAlarm from './components/Alarms/DetailAlarm';

const activityService = new ActivityService();
const customerService = new CustomerService();
const alarmService = new AlarmService();

const NotificationTodo = (props) => {
  const {anchorElUser, setAnchorElUser, user, mm} = props;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const msgAvailable = useSelector(({mm}) => mm.notification) || {date: null, options: []};
  const {blockUI, snackbarUI} = useUI();
  const headerStyle = HeaderStyles();
  const wizardStyle = WizardStyles();
  const notificationsStyle = NotificationsStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentDate = new Date().toLocaleDateString();
  const [valueTagNotification, setValueTagNotification] = React.useState(0);
  const [openAlarmView, setOpenAlarmView] = useState(false);
  const [dataAlarmDetail, setdataAlarmDetail] = useState({});


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleReadNotify = useCallback(async (i) => {
    try {
      blockUI.current.open(true);
      activityService.getAccessToken();
      await activityService.update({status: 0}, i);
      const notifications = msgAvailable?.options?.map((message) => {
        return (message.id === i) ? ({...message, status: 0}) : ({...message})
      });
      props.dispatch(addNotification(notifications));
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
    }
  }, [blockUI, msgAvailable?.options, props]);

  /* useEffect(() => {
    window.Echo.channel(`ChannelActivityCreated`).listen(
      "ActivityCreated",
      (event) => {
        console.log("event", event);
        props.dispatch(removeNotification());
      }
    );
  }, []); */

  const getNotificationsTodo = useCallback(async () => {
    try {
      const dateDay = new Date().toLocaleDateString();
      if (msgAvailable.date !== dateDay) {
        blockUI.current.open(true);
        activityService.getAccessToken();
        alarmService.getAccessToken();
        const include = '&include=customerAttribute&nested=false';
        const activities = await activityService
          .list(`?filter[user_id]=${user.id}&filter[nextDay]=1${include}`);
        const hora = new Date().getHours();

        let resultFinalAlarm = [];
        const options = activities.data.map((e) => ({
          ...e,
          available: (hora >= Math.abs(e.duration / 60 - 24))
        }));
        let resultFinal = options;

        if(user.roles[0] === 'Admin' || user.roles[0] === 'Manager'){
          const activitiesAdmin = await activityService.list(`?filter[type_id]=3${include}`);
          const optionsAdmin = activitiesAdmin.data.map((e) => ({
            ...e,
            available: true
          }));
          resultFinal = [...options, ...optionsAdmin];
          const { data : optionsAdminAlarm} = await alarmService.list(`?render=paginate&include=users&sort=-id&limit=50&filter[userId]=${user.id}`);
          resultFinalAlarm = optionsAdminAlarm.data;
        }

        const common = await activityService
          .list(`?filter[user_id]=${user.id}&filter[type_id]=4${include}`);
        const optionsCommon = common.data.map((e) => ({
          ...e,
          available: true
        }));

        resultFinal = [...resultFinal, ...optionsCommon];

        props.dispatch(addNotification(resultFinal));
        props.dispatch(addAlarm(resultFinalAlarm));
        blockUI.current.open(false);
      }
    } catch (e) {
      blockUI.current.open(false);
    }
  }, [blockUI, msgAvailable.date, props, user.id, user.roles]);

  const handleRemoveAlarm = (alarmsAvailable, idsDelete) => {
    try {
      const newAlarms = alarmsAvailable.options.filter((e)=>(!idsDelete.includes(e.id)));
      props.dispatch(addAlarm(newAlarms));
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const timerUpdate = useCallback(() => {
    const hora = new Date().getHours();
    const notifications = mm.notification?.options?.map((e) => {
      if (e.available) {
        return {...e};
      } else {
        return {
          ...e,
          available: (hora >= Math.abs(e.duration / 60 - 24))
        }
      }
    });
    props.dispatch(addNotification(notifications));
  }, [mm.notification?.options, props]);

  const timerUpdateAlarm = useCallback(() => {

    let todayLocale = new Date().toLocaleDateString();
    let notificationsCustomize = mm.alarm?.options?.map((e) => {
      if (new Date(e.alarmDate).getTime() === new Date(todayLocale).getTime() && e.active === true){
        if(e.snooze){
          let dateHour = new Date(`${e.alarmDate} ${e.alarmHour}`);
          dateHour.setMinutes(dateHour.getMinutes() + Number(e.duration));
          let hour = dateHour.getHours();
          let minute = dateHour.getMinutes();
          if(hour.toString().length === 1){
            hour = `0${hour}`;
          }
          if(minute.toString().length === 1){
            minute = `0${minute}`;
          }
          const horaUpdated = `${hour}:${minute}`;
          return {
            ...e,
            alarmHour: horaUpdated
          }
        }else{
          return e;
        }
      }
    });

    notificationsCustomize = notificationsCustomize.filter((e)=>(e!==undefined));

    let timeToday = new Date();
    let hour = timeToday.getHours();
    let minute = timeToday.getMinutes();
    if(hour.toString().length === 1){
      hour = `0${hour}`;
    }
    if(minute.toString().length === 1){
      minute = `0${minute}`;
    }
    let hourExactly = `${hour}:${minute}`;
    notificationsCustomize.map((e)=>{
      if(e.alarmHour === hourExactly){
        setdataAlarmDetail(e);
        setOpenAlarmView(true);
      }
    });
  }, [mm.alarm?.options, props]);

  const handleRedirectCustomerDeleted = async (id, type, idNotification) => {
    try {
      if(type ===3){
        blockUI.current.open(true);
        await handleReadNotify(idNotification);
        customerService.getAccessToken();
        const customer = await customerService.read(id);
        dispatch(addCustomer({...customer.data}));
        history.push('/edit-customer', {...customer.data});
        blockUI.current.open(false);
        setAnchorElUser(null);
      }
    } catch (e) {
      blockUI.current.open(false);
    }
  }

  const handleMarkAllRead = useCallback(async () => {
    try {
      blockUI.current.open(true);
      activityService.getAccessToken();
      let ids = mm.notification.options.map((e)=>e.id);
      await activityService.updateAll({ids});
      const notifications = msgAvailable?.options?.map((message) => {
        return {...message, status: 0}
      });
      props.dispatch(addNotification(notifications));
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
    }
  }, [blockUI, mm.notification.options, msgAvailable, props]);

  const handleUpdateStatusAlarm = useCallback(async (data, idAlarm, newStatus) => {
    try {
      blockUI.current.open(true);
      alarmService.getAccessToken();
      await alarmService.updateStatus(data, idAlarm);
      props.dispatch(addAlarm(newStatus));
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
    }
  }, [blockUI, props]);

  const handleUpdateDataAlarm= useCallback(async (data, idAlarm, newStatus) => {
    try {
      blockUI.current.open(true);
      alarmService.getAccessToken();
      await alarmService.update(data, idAlarm);
      props.dispatch(addAlarm(newStatus));
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
    }
  }, [blockUI, props]);

  const handleAddedAlarm= useCallback(async (data) => {
    try {
      blockUI.current.open(true);
      alarmService.getAccessToken();
      await alarmService.create(data);
      const { data : optionsAdminAlarm} = await alarmService.list(`?render=paginate&include=users&sort=-id&limit=50&filter[userId]=${user.id}`);
      let resultFinalAlarm = optionsAdminAlarm.data;
      props.dispatch(addAlarm(resultFinalAlarm));
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
    }
  }, [blockUI, props]);

  const notifications = (type) => {
    let notificationsAvailable = msgAvailable?.options?.filter((e) => {
      let date = new Date(e.date).toLocaleDateString();
      switch(type){
        case 'today': return (date === currentDate) && e;
        case 'older': return (date !== currentDate) && e;
        case 'unreadToday': return (date === currentDate && e.status === 1) && e;
        case 'unreadOlder': return (date !== currentDate && e.status === 1) && e;
      }
    });

    return (
      <>
        {
          (notificationsAvailable.length > 0)
            ?
              notificationsAvailable.map(({id, date, duration, customerAttributeFullName, name, status, available, typeId, customerAttributesId}) => {

                return <div key={id} className={(available) ? wizardStyle.availableNotify : wizardStyle.unavailableNotify}>
                        <div className={(status === 0) ? wizardStyle.notifyWrapperRead : wizardStyle.notifyWrapper}>
                          <ListItem button onClick={()=>{handleRedirectCustomerDeleted(customerAttributesId, typeId, id)}}>
                            <ListItemAvatar>
                              <Tooltip title={customerAttributeFullName} placement="left">
                                <Avatar>{!_isEmpty(customerAttributeFullName) ? customerAttributeFullName.charAt(0) : ''}</Avatar>
                              </Tooltip>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <div>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="textPrimary"
                                    className={wizardStyle.notifyPrimary}
                                  >
                                    Activity: {date.slice(0, -5)}
                                  </Typography>
                                </div>
                              }
                              secondary={
                                <>
                                  {
                                    (duration && typeId !== 4)
                                      &&
                                        <>
                                          <Typography
                                            component="span"
                                            variant="body2"
                                            color="textPrimary"
                                            className={wizardStyle.infoReminder}
                                          >
                                            Reminder: {duration / 60}º before
                                          </Typography>
                                          <br/>
                                        </>

                                  }
                                  <span
                                    className={wizardStyle.descriptionNotifyRead}>
                                    {name}
                                  </span>
                                  {/* <span
                                    className={(status === 0) ? wizardStyle.unavailableNotify : wizardStyle.wrapperBtnNotify}>
                                    <IconButton size="small" className={wizardStyle.btnNotify}
                                                onClick={() => handleReadNotify(id)}>
                                      See more
                                      <ArrowDropDownIcon fontSize="inherit"/>
                                    </IconButton>
                                  </span> */}
                                </>
                              }
                            />
                            <ListItemSecondaryAction className={wizardStyle.notifySecondary}>
                              <IconButton edge="end" aria-label="comments" className="btnNotifySecondary">
                                <FormControlLabel
                                  control={<Checkbox icon={(status === 0) ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />} checkedIcon={<CheckCircleIcon />} onClick={() => handleReadNotify(id)} />}
                                />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </div>
                      </div>

              })
            :
              (
                <div className={wizardStyle.infoNotNotification}>
                  There are no pending notifications
                </div>
              )
        }
      </>
    );
  }

  useEffect(() => {
    (async function init() {
      await getNotificationsTodo();
    })();
  }, [getNotificationsTodo]);

  useEffect(() => {
    const interval = setInterval(() => {
      timerUpdate();
    }, 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, [timerUpdate]);

  useEffect(() => {
    const interval = setInterval(() => {
      timerUpdateAlarm();
    }, 40 * 1000);
    return () => clearInterval(interval);
  }, [timerUpdateAlarm]);

  return (
    <>
      <Popover
        id="menu-appbar"
        open={Boolean(anchorElUser)}
        anchorEl={anchorElUser}
        onClose={handleCloseUserMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        classes={{
          paper: 'py-8'
        }}
      >
        <Paper square className={headerStyle.popover}>

          <Grid container>
            <Grid item xs={12}>
              <BottomNavigation
                value={valueTagNotification}
                onChange={(event, newValue) => setValueTagNotification(newValue)}
                showLabels
                className={notificationsStyle.root}
              >
                <BottomNavigationAction label="Notification" />
                <BottomNavigationAction label="Alarms" />
              </BottomNavigation>
            </Grid>
          </Grid>

          {
            (valueTagNotification === 0)
              ? <Notification handleMarkAllRead={handleMarkAllRead} notifications={notifications}/>
              : <Alarms
                  handleUpdateStatusAlarm={handleUpdateStatusAlarm}
                  handleRemoveAlarm={handleRemoveAlarm}
                  handleUpdateDataAlarm={handleUpdateDataAlarm}
                  handleAddedAlarm={handleAddedAlarm}
                  openAlarmView={openAlarmView}
                  setOpenAlarmView={setOpenAlarmView}
                  dataAlarmDetail={dataAlarmDetail}
                  setdataAlarmDetail={setdataAlarmDetail}
                />
          }

        </Paper>
      </Popover>
      <DetailAlarm
        openAlarmView={openAlarmView}
        setOpenAlarmView={setOpenAlarmView}
        dataAlarmDetail={dataAlarmDetail}
        handleUpdateDataAlarm={handleUpdateDataAlarm}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    mm: state.mm
  };
};

export default connect(mapStateToProps)(NotificationTodo);
